@import '../../../../../../variables.css';

.container {
  width: 220px;
  max-height: 258px;
  display: inline-grid;
  grid-template-rows: auto 100px auto auto;
  justify-items: center;
  align-items: center;
  row-gap: 7px;
  border-radius: 9px;
  padding: 16px;
  color: var(--ark-primary-white-figma);
  background-color: #0E1858;
  border: 3px solid #9912D0;
  font-weight: var(--ark-font-weight-bold);
  font-size: var(--ark-21-font-size);
  line-height: var(--ark-29-line-height);
  overflow: hidden;
}

.altContainer {
  border-color: var(--ark-hover-light-natalie-blue-figma);
  background-image: url(./images/gems/gemcard-background.png);
  background-repeat: no-repeat;
  background-size: auto;
}

.value {
  display: inline-flex;
  align-items: center;
  gap: 7px;
}

.graphicContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.graphic {
  width: auto;
  max-height: 138px;
}

.altGraphic {
  margin-right: 25%;
}

.ribbon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: var(--ark-font-weight-extraBold);
  font-size: var(--ark-15-font-size);
  line-height: var(--ark-18-line-height);
  text-align: center;
  color: var(--ark-primary-black-figma);
  text-shadow: 1px 0 1px rgb(249 249 249), 0 1px 1px rgb(249 249 249), -1px 0 1px rgb(249 249 249),
    0 -1px 1px rgb(249 249 249);
  filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15));
  background-image: url(./images/gems/gemcard-ribbon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.ribbonText {
  width: -moz-min-content;
  width: min-content;
}

.saving {
  font-weight: var(--ark-font-weight-bold);
  font-size: var(--ark-14-font-size);
  line-height: var(--ark-20-line-height);
}

.button {
  width: 100%;
  color: var(--ark-neutral-white) !important;
  border-color: #12B76A !important;
  background-color: transparent !important;
}

.button:hover {
  background-color: #12B76A !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.30) !important;
}

.altButton {
  color: var(--ark-primary-black-figma);
  background: #E3BD4B;
}

.altButton:hover {
  background: #F1E2AD;
  color: #000000 !important;
}

.oldPrice {
  font-weight: var(--ark-font-weight-regular);
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
  margin-right: 7px;
}

.subscriberButton {
  border-color: #FEC84B !important;
}

.subscriberButton:hover {
  background-color: #FEC84B !important;
}

/* width: 768 - 1024 */
@media (min-width: var(--ark-medium-mobile-figma-768)) and (max-width: var(--ark-small-desktop-figma-1024)) {
  .container {
    width: 205px;
  }
}

/* width: 600 - 767 */
@media (min-width: var(--ark-small-mobile-figma-600)) and (max-width: var(--ark-medium-mobile-figma-767)) {
  .container {
    width: 180px;
    font-size: var(--ark-19-font-size);
    line-height: var(--ark-27-line-height);
  }

  .gem {
    width: 22px;
  }

  .ribbon {
    width: 78px;
    height: 78px;
    background-size: 90px;
    font-size: var(--ark-14-font-size);
    line-height: var(--ark-16-line-height);
  }
}

/* width: 0 - 599 OR width 0 - 767 and orientation landscape */
@media (max-width: var(--ark-small-mobile-figma-below-599)),
(max-width: var(--ark-medium-mobile-figma-767)) and (orientation: landscape) {
  .container {
    width: 136px;
    grid-template-rows: auto 80px auto auto;
    row-gap: 2px;
    padding: 10px;
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-18-line-height);
  }

  .gem {
    width: 15px;
  }

  .graphic {
    max-height: 80px;
  }

  .ribbon {
    width: 57px;
    height: 57px;
    background-size: 70px;
    font-size: var(--ark-13-font-size);
    line-height: var(--ark-16-line-height);
  }

  .saving {
    font-size: var(--ark-9-font-size);
    line-height: var(--ark-14-line-height);
  }

  .button {
    min-height: unset;
    height: 24px;
    font-size: var(--ark-11-font-size);
    line-height: var(--ark-14-line-height);
  }

  .oldPrice {
    font-size: var(--ark-10-font-size);
    margin-right: 2px;
  }
}
